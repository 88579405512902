import Vue from 'vue';
import VueRouter from 'vue-router';

import NotFound from "@/components/NotFound.vue";
import MainComponent from "@/components/MainComponent.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: '/*',
    component: MainComponent,
  },
  {
    path: '*',
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
