<template>
  <div>
    <h1>Loading...</h1>
  </div>
</template>

<script>

import axios from "axios";

export default {
  name: 'MainComponent',
  async created() {
    const url_path = window.location.href.split('/').at(-1)
    const { data: {url} } = await axios.get(`${process.env.VUE_APP_BACKEND_HACKERNEWS}/posts/get-url-by-path?path=${url_path}`);
    window.location.href = url;
  },
}
</script>

<style lang="scss" scoped>
</style>